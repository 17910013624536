
@media screen and (max-width:992px){
    .col-12.col-md-4.col-lg-4.col-xl-4.row:nth-child(2) {
        padding: 6px;
    }
    .leftPresale .pc1 {
        margin-top: 50px;
    }
    .col-12.col-md-12.col-lg-8.col-xl-8.rightPresale {
        width: 100%;
        margin: 0px auto;
    }
    .bnbButtonWrapper.my-3 {
        background: #282d34;
        padding: 10px 0px;
        border-radius: 18px;
        width: 100%;
        margin: auto;
    }
    button.nextBtn {
        display: block;
        float: right;
        margin-top: 16px !important;
      
       
    }
    .col-12.col-md-11.col-lg-11.col-xl-11.m-auto.leftPresale {
        margin-left: 0px !important;
    }
    .bnbButtonWrapper.my-3 {
       
        margin-left: 0px !important;
   
    }
}
@media screen and (max-width:768px){
      #mainSocial{
        display:flex;
        justify-content:center;
        width:100%;
        align-items:center
    }
    .box_wrapper_{width:100%; flex-direction:column}
    .box_{
        width:100%;
        padding:10px ;
        margin:20px 0px;
        h2{
            font-weight:bold;
        }
    }
    .footer_devide{
        width:100% !important;
        align-items:start;
        justify-content:start !important;
        ; margin-top:70px;
        .brand{
            width:100% !important;
            object-fit:fill !important
        }
        h2{
            text-align:start
           
        }
    }
    .sociallll{
        width:100% !important;
       
        align-items:start !important;
        justify-content:start !important;
        padding-bottom: 30px;
        margin-top:40px
    }
    .footer_sub_wrapper{
        display:flex;
        flex-direction:column;
        margin-top:120px !important;
      
    }
    .cwrapper {
        margin-left: -20px;
        margin-top: 29px !important;
      
    }
    .leftPresale .pc1 {
        margin-top: 38px;
    }
    .light span.ptnr{
        position:relative !important;
    }
    .homeTitleWrap{
        flex-direction:column;
        padding:10px 0px;
        align-items:center;
        justify-content:center;
        h1{text-align:center;}
        p{text-align:center;}
    }
    .wnavLeft {
        display: none !important;
    }
    .pdot{
        display:inline-block;
        visibility:hidden;
    }
    .logo_font {
        display: none;
    }
    
    .side_nav div#fixNav {
        position: absolute;
        left: -100%;
        z-index:20;
        width: 192px;
        
        height: 100vh;
    }
    .col-11.col-md-11.col-lg-11.col-xl-11.home_s_box.m-auto {
        /* margin-top: 42px !important; */
        margin: 10px auto !important;
        text-align: center;
        width: 98%;
    }
    .col-12.col-md-4.col-lg-4.col-xl-4.row {
        padding-right: 0px;
    }
    .dlogo{
        display:block;
    }
    .wnavRight{
        display:none;
    }
#navbarBtn{
    display:block;
}
#sidinav{
    display:block;
    position:absolute;
    
}
#navi22{display:none;}
div#fixNav {
    background: #e7eff3;
}
.home_contributaion {
    margin-top: 100px;
    display: flex;
    justify-content: center;
    flex-direction: column;
}
.home_contributaion .contribution_wrap {
    display: flex;
    justify-content: space-evenly;
    width:100%;
}
.contribution_content {
    text-align: center;
}
.contribution_wrap:nth-child(3) {
    margin-top: 17px;
    margin-left: -6px;
}
h2{    text-align: center;
    font-size: 24px;
    margin-top: 30px;
}
.container.presaleWrap.mx-auto.row {
    margin-top: 68px;
    display: flex;
    align-items: start;
    justify-content: center;
}
.col-12.col-md-8.col-lg-8.col-xl-8.rightPresale {
    margin-top: 30px;
}
.WalNav button {
    background: transparent;
    border: none;
    outline: none;
    border-radius: 10px;
    padding: 6px 9px;
    margin-right: 12px;
    font-weight: bold;
    transition: 0.3s;
    color: $acolor;
}
.home_second_wrap.row {
    width: 100%;
    margin: auto;
    padding: 0px;
    margin-left: 5px;
    color:$acolor;
}
.wrn01 {
    margin-top: 407px;
}
.col-12.col-md-8.col-lg-8.col-xl-8.rightPresale {
    z-index: 7;
}
.col-12.col-md-11.rightPresale {
    z-index: 7;
}
button.connect {
    background: transparent;
    color: $acolor;
}
}


@media screen and (max-width:500px){
    
}


@media screen and (max-width:400px){
    
}